import React, { useState, useContext } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
  Fade,
} from "@material-ui/core";
// import { withRouter } from "react-router-dom";
import { useNavigate  } from "react-router-dom";
// import classnames from "classnames";

// styles
import useStyles from "./styles";

// logo
import logo from "./Re2PayLogo0.png";
import icon from "./Re2PayIcon0.png";
// import google from "../../images/google.svg";

// context
// import { useUserDispatch, loginUser, registerUser } from "../../context/AuthContext";
import { AuthContext } from "../../context/AuthContext";

function Login() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { login, register, authVerify } = useContext(AuthContext)
  const { getAuthUser} = useContext(AuthContext);
  const authUser = getAuthUser();

  // local
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [activeTabId, setActiveTabId] = useState(0);
  const [loggedinEmail, setLoggedinEmail] = useState("");
  const [authCode, setAuthCode] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");

  const handleChangeTab = (id) => {
    // alert(JSON.stringify(authUser));
    // if (id === 1 && !authUser) {
    //   return;
    // }
    // setActiveTabId(id)
    // setErrorMessage(null)
  }

  const handleLogin = async () => {
    const isLoggedin = await login(
      emailValue,
      passwordValue,
      // props.history,
      setIsLoading,
      setErrorMessage
    );
    // console.log('ggg', isLoggedin);
    if (isLoggedin) {
      // navigate('/dashboard');
      setActiveTabId(1)
    }
  }

  const handleAuch = async () => {
    // alert(authUser.email);
    const isVerified = await authVerify(
      authUser.email,
      authCode,
      // props.history,
      setIsLoading,
      setErrorMessage,
    )
    console.log(isVerified);
    // alert(isRegistered);
    if (isVerified) {
      navigate('/dashboard');
    }
  }

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <img src={icon} alt="Re2Pay" className={classes.logotypeImage} />
        <img src={logo} alt="Re2Pay" className={classes.logotypeImage} />
        {/* <Typography className={classes.logotypeText}>Re2Pay</Typography> */}
      </div>
      <div className={classes.formContainer}>
        <div className={classes.form}>
          <Tabs
            value={activeTabId}
            onChange={(e, id) => handleChangeTab(id)}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="Login" classes={{ root: classes.tab }} />
            {/* <Tab label="New User" classes={{ root: classes.tab }} /> */}
          </Tabs>

          {activeTabId === 0 && (
            <React.Fragment>

                {errorMessage && (
                  <Fade in={true}>
                    <Typography color="secondary" className={classes.errorMessage}>
                      {errorMessage.toString()}
                    </Typography>
                  </Fade>
                )}

                <TextField
                  id="email"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  sx={{
                    "& .MuiFormLabel-root": {
                      color: "white !important",
                    },
                  }}
                  value={emailValue}
                  onChange={e => setEmailValue(e.target.value)}
                  margin="normal"
                  label="Email"
                  placeholder="Email"
                  type="email"
                  fullWidth
                />
                <TextField
                  id="password"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  value={passwordValue}
                  onChange={e => setPasswordValue(e.target.value)}
                  margin="normal"
                  label="Password"
                  placeholder="Password"
                  type="password"
                  fullWidth
                />
                <div className={classes.formButtons}>
                  {isLoading ? (
                    <CircularProgress size={26} className={classes.loginLoader} />
                  ) : (

                    <Button
                      disabled={
                        emailValue.length === 0 || passwordValue.length === 0
                      }
                      onClick={handleLogin}
                      variant="contained"
                      color="primary"
                      size="large"
                    >
                      Login
                    </Button>

                  )}
                  
                </div>

            </React.Fragment>
          )}
          {activeTabId === 1 && (
            <React.Fragment>
              
              {errorMessage && (
                <Fade in={true}>
                  <Typography color="secondary" className={classes.errorMessage}>
                    {errorMessage}
                  </Typography>
                </Fade>
              )}
              <TextField
                id="authCode"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={authCode}
                onChange={e => setAuthCode(e.target.value)}
                margin="normal"
                label="Auth Code"
                placeholder="Auth Code"
                type="text"
                fullWidth
              />
              
              <div className={classes.creatingButtonContainer}>
                {isLoading ? (
                  <CircularProgress size={26} />
                ) : (
                  <Button
                    onClick={handleAuch}
                    disabled={
                      authCode.length === 0
                    }
                    size="large"
                    variant="contained"
                    color="primary"
                    fullWidth
                    className={classes.createAccountButton}
                  >
                    Submit
                  </Button>
                )}
              </div>
              
            </React.Fragment>
          )}

        </div>
        <Typography color="primary" className={classes.copyright}>
          © 2024 Re2pay. All rights reserved.
        </Typography>
      </div>
    </Grid>
  );
}

// export default withRouter(Login);
export default Login;
