import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate  } from "react-router-dom";
import { Box, useTheme, Button } from "@mui/material";
import FlexBetween from "components/FlexBetween";
import { useGetUserActivityQuery, generalApi } from "state/api";
import Header from "components/Header";
import { DataGrid } from "@mui/x-data-grid";
// import { merchantTableColumns } from "utilities/CommonUtility";
import DataGridCustomToolbarForMerchants from "components/DataGridCustomToolbarForMerchants";
import { DataGridPro, GridToolbar, GridToolbarContainer } from "@mui/x-data-grid-pro";
import { LicenseInfo } from '@mui/x-license-pro';
import Swal from 'sweetalert2';

import axios from "axios";

LicenseInfo.setLicenseKey('3beb4fd4938a341fb6f47fe77d622867Tz03NDQ5MixFPTE3MjYxNTU5NDUwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='); // expiry 2024/09/11

const http = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-type": "application/json"
  }
});

const Logs = ({ selectedRow, setIsLogging }) => {
  const theme = useTheme();

  const id = selectedRow._id;

  const { getAuthUser} = useContext(AuthContext);
  const authUser = getAuthUser();
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [sort, setSort] = useState({});
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchTransactions = () => {
    setLoading(true); // console.log('start-fetch', loading);
    http
    .get(
      `/client/userActivity`, {
      params: { 
        email: selectedRow.email, 
        page, 
        pageSize, 
        sort: JSON.stringify(sort), 
      }
    })
    .then(res => {
      setData(res.data);
    })
    .catch(err => console.log(err.log))
    .finally(() => setLoading(false));
  }

  useEffect(() => {
    fetchTransactions();
  }, [page, pageSize]);

  const handlePaginationModelChange = (newModel) => {
    // console.log(newModel);
    setPage(newModel.page);
    setPageSize(newModel.pageSize);
  }

  const merchantTableColumns = [
    {
      field: "createdAt",
      headerName: "Activity Date",
      flex: 1,
    },
    // {
    //   field: "method",
    //   headerName: "Request Method",
    //   flex: 0.5
    // },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
    },
    {
      field: "payload",
      headerName: "Payload",
      flex: 3
    },
    {
      field: "ipAddress",
      headerName: "IP address",
      flex: 1
    },
    // {
    //   field: "actions",
    //   headerName: "Actions",    
    //   sortable: false,
    //   filterable: false,
    //   flex: 1,
    //   // hide: true,
    //   renderCell: (params) => (
    //     <>
    //       <Button id="delete" variant="contained" onClick={() => handleDelete(params.row)}>Delete</Button>
    //     </>      
    //   )
    // },
  ];
  
  return (
    <Box>
      <FlexBetween>
        <Header title="" subTitle={"User Activity - " + selectedRow.name} />
        <Button id="cancel" variant="contained" onClick={() => setIsLogging(false)}>Close</Button>
      </FlexBetween>      
      <Box  
        mt="0.5rem"      
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGridPro
          sx={{
            "& .css-zkx8c-MuiCircularProgress-root": {
              color: theme.palette.secondary[100],
            }
          }}
          // getRowClassName={getRowClassName}
          loading={loading}
          getRowId={(row) => row._id}
          rows={(data && data.logs) || []}
          columns={merchantTableColumns}
          rowCount={(data && data.total) || 0}
          rowHeight={70}
          pagination
          paginationMode={"server"}
          onPaginationModelChange={handlePaginationModelChange}
          pageSizeOptions={[20,100,1000,10000,20000]}
          paginationModel={{page: page, pageSize: pageSize}}
          onSortModelChange={(newSortModel) => setSort(newSortModel)}
        />
      </Box>      
    </Box>
  );
};

export default Logs;
